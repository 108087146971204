import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import SplitLayout from '../../components/common/splitLayout'
import Seo from '../../components/common/seo'
import media from '../../components/css/media'
import Error from '../../components/common/errorMessage'
import Success from '../../components/common/successMessage'

import { doResetPassword } from '../../adapters/auth'
import { parseQueryString } from '../../utils/generic'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import {
  passwordRegex,
  handlePasswordKeyDown,
} from '../../utils/passwordValidation'

import EyeIcon from '../../images/icon_eye.svg'
import EyeSlashIcon from '../../images/icon_eye_slash.svg'

const Content = styled.div`
  position: relative;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  form {
    max-width: 80%;
    width: 100%;
    margin: 0 auto;
  }

  ${media.desktop`
    form {
      max-width: 500px;
    }
  `};
`

const PasswordWrapper = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 30%;
    right: 5%;
    cursor: pointer;
  }
`

const Eye = styled.img`
  width: 20px;
  position: absolute;
  right: 5%;
  top: 15px;
  cursor: pointer;
`

const EyeSlash = styled.img`
  width: 20px;
  position: absolute;
  right: 5%;
  top: 15px;
  cursor: pointer;
`

const ResetPassword = ({ pageContext: { title } }) => {
  const { isMobile } = useDeviceDetect()
  const intl = useIntl()

  const data = useStaticQuery(graphql`
    {
      otherBannersApi {
        values {
          lang
          otherBanners {
            background
            backgroundMobile
            banner_title_text
            text_color
            type
          }
        }
      }
      customSlugsApi {
        values {
          lang
          slugs {
            generic_route
            language_route
          }
        }
      }
    }
  `)

  const {
    otherBannersApi: { values },
    customSlugsApi: { values: customSlugValues },
  } = data

  const bannerExistForLocale = values.some((row) => row.lang === intl.locale)
  const bannerExistForPage =
    bannerExistForLocale &&
    values
      .filter((row) => row.lang === intl.locale)[0]
      .otherBanners.some((i) => i.type === 'resetPassword')
  const banner = bannerExistForPage
    ? values
        .filter((row) => row.lang === intl.locale)[0]
        .otherBanners.filter((i) => i.type === 'resetPassword')[0]
    : null

  const slugsExistForLocale = customSlugValues.some(
    (row) => row.lang === intl.locale
  )
  const slugsExistForPage =
    slugsExistForLocale &&
    customSlugValues.filter((row) => row.lang === intl.locale)[0].slugs
  const slugs = slugsExistForPage
    ? customSlugValues.filter((row) => row.lang === intl.locale)[0].slugs
    : []

  const loginSlug = slugs.some(
    (slug) => slug.generic_route.toLowerCase() === 'login'
  )
    ? slugs.filter((slug) => slug.generic_route.toLowerCase() === 'login')[0]
        .language_route
    : 'login'

  const image = banner ? banner.background : null
  const imageMobile = banner ? banner.backgroundMobile : null
  const text = banner ? banner.banner_title_text : null
  const textColor = banner ? banner.text_color : null

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, touchedFields },
  } = useForm()

  const location = useLocation()
  const params = parseQueryString(location.search)

  const [response, setResponse] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const password = useRef({})
  password.current = watch('password', '')

  const onSubmit = async (data) => {
    const model = {
      password: data.password,
      confirm_password: data.confirmPassword,
      token: params.token,
    }

    const response = await doResetPassword(model)
    setResponse(response)
    if (response.ok)
      setTimeout(() => navigate(`/${intl.locale}/${loginSlug}/`), 3000)
  }

  return (
    <SplitLayout
      img={isMobile ? imageMobile : image}
      text={text}
      textColor={textColor}
      hideLeftSideOnMobile={true}
    >
      <Seo title={title} />

      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1>
            <FormattedMessage id='resetPassword.header' />
          </h1>

          <label htmlFor='password'>
            <FormattedMessage id='common.password' />
          </label>
          <PasswordWrapper>
            <input
              className={`${errors.password ? 'invalid' : ''} ${
                !errors.password && touchedFields.password ? 'valid' : ''
              }`}
              type={`${showPassword ? 'text' : 'password'}`}
              ref={password}
              onKeyDown={handlePasswordKeyDown}
              {...register('password', {
                required: true,
                pattern: {
                  value: passwordRegex,
                  message: intl.formatMessage({ id: 'common.passwordHint' }),
                },
              })}
            />
            {!showPassword && (
              <Eye
                src={EyeIcon}
                alt='Show Password'
                onClick={() => setShowPassword(!showPassword)}
              />
            )}
            {showPassword && (
              <EyeSlash
                src={EyeSlashIcon}
                alt='Hide Password'
                onClick={() => setShowPassword(!showPassword)}
              />
            )}
          </PasswordWrapper>

          <small>
            <FormattedMessage id='common.passwordHint' />
          </small>

          <label htmlFor='confirmPassword'>
            <FormattedMessage id='common.confirmPassword' />
          </label>
          <PasswordWrapper>
            <input
              className={`${errors.confirmPassword ? 'invalid' : ''} ${
                !errors.confirmPassword && touchedFields.confirmPassword
                  ? 'valid'
                  : ''
              }`}
              type={`${showConfirmPassword ? 'text' : 'password'}`}
              onKeyDown={handlePasswordKeyDown}
              {...register('confirmPassword', {
                required: true,
                pattern: {
                  value: passwordRegex,
                  message: intl.formatMessage({ id: 'common.passwordHint' }),
                },
                validate: (value) =>
                  value === password.current ||
                  intl.formatMessage({ id: 'common.passwordDoNotMatch' }),
              })}
            />
            {!showConfirmPassword && (
              <Eye
                src={EyeIcon}
                alt='Show Password'
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            )}
            {showConfirmPassword && (
              <EyeSlash
                src={EyeSlashIcon}
                alt='Hide Password'
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            )}
          </PasswordWrapper>

          <input
            type='submit'
            value={intl.formatMessage({ id: 'resetPassword.header' })}
          />

          {response && !response.ok && (
            <Error text={`${response.error.message}`} />
          )}

          {errors.confirmPassword && (
            <Error text={`${errors.confirmPassword.message}`} />
          )}

          {response && response.ok && (
            <Success
              text={intl.formatMessage({
                id: 'resetPassword.passwordChangedSuccess',
              })}
            />
          )}
        </form>
      </Content>
    </SplitLayout>
  )
}

export default ResetPassword
